<template>
    <div>
        <st-button
            v-can:create="'application'"
            variant="secondary"
            :callback="addApplication"
        >
            <i class="fa fa-plus"></i>
            {{ $t('APPLICATION.LIST.ADD_BUTTON') }}
        </st-button>
        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="canSign"
            :callback="signDocument"
            :disabled="signBtnDisabled || isSignLoading"
            :spinner="isSignLoading"
        >
            <i class="fa fa-save"></i>
            {{ $t("APPLICATION.LIST.SIGN_DOCUMENT_BULK") }}
        </st-button>
        <st-button
            variant="dark"
            customClass="application-refresh-button"
            :callback="refresh"
        >
            <i class="fas fa-sync"></i>
            {{ $t("APPLICATION.LIST.REFRESH_BUTTON") }}
        </st-button>
        <st-button
            :callback="openGenerateApplicationsReportModal"
            v-if="hasPermissionToPredefinedReports"
        >
            <i class="fas fa-file-csv"></i>
            {{ $t("APPLICATION.LIST.REPORT_BUTTON") }}
        </st-button>
        <confirm-sign-document-modal
            ref="confirm-sign-document-modal"
            :bulkSigning="true"
            :applicationId="selectedRows"
            @signed="refreshStaffApplications"
        />
        <generate-applications-report-modal ref="generate-applications-report-modal" />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import ConfirmSignDocumentModal from "@/modules/applications/components/view-application/ConfirmSignDocumentModal";
    import Message from "@/shared/message/message";
    import GenerateApplicationsReportModal  from '@/modules/applications/components/generate-report/GenerateApplicationsReportModal.vue';
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';

    export default {
        name: 'ApplicationsListToolbar',
        props: {
            canSign: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ConfirmSignDocumentModal,
            GenerateApplicationsReportModal
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                selectedRows: "applications/list/selectedRows",
                loading: 'shared/loading',
                appConfig: 'shared/appConfig',
            }),
            signBtnDisabled() {
                const length = this.selectedRows.length;

                if (!length) {
                    return true;
                }

                const limit = this.appConfig?.BULK_SIGN_LIMIT;
                if (limit && length > limit) {
                    return true;
                }

                return false;
            },
            isSignLoading() {
                return this.loading["applications/signDocumentBulk"];
            },
            hasPermissionToPredefinedReports() {
                return new ApplicationsPermissions(this.currentUser).viewPredefinedReports;
            },
        },
        methods: {
            ...mapActions({
                signDocumentBulk: 'applications/form/signDocumentBulk',
                setSelectedRows: 'applications/list/doSelect',
                getAssignedApplications: 'getAssignedApplicationsCount',
            }),
            addApplication() {
                this.$router.push({ name: 'applicationNew' });
            },
            signDocument() {
                let applicationIds = this.selectedRows.map((el) => el.id);

                this.signDocumentBulk(applicationIds).then((response) => {
                    this.$refs['confirm-sign-document-modal'].show(response);
                }).catch(() => {
                    Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
                });
            },
            async refreshStaffApplications() {
                this.refresh()
                this.setSelectedRows([]);
                // Update assigned application(s) count on side menu
                const payload = {
                    params: {
                        skip: 0,
                        limit: this.appConfig.RECORDS_PER_PAGE,
                        pagination: true,
                        assigned: true,
                    },
                };
                this.getAssignedApplications(payload)
            },
            refresh () {
                this.$emit('refreshApplicationsList');
            },
            openGenerateApplicationsReportModal() {
                this.$refs['generate-applications-report-modal'].show();
            }
        },
    };
</script>
