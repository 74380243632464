<template>
  <div>
    <st-modal
      id="st-generate-applications-report-modal"
      hide-header-delimiter
      hide-footer-delimiter
      size="lg"
      hideable
      ref="st-generate-applications-report-modal"
      customClass="form-modal"
    >
      <template #header>
        <p class="font-size-h5 font-weight-bold mb-4 pb-0">
          {{ $t("APPLICATION.LIST.REPORT_BUTTON") }}
        </p>
      </template>
      <template #body>
        <generate-applications-report-form ref="st-generate-applications-report-form" />
      </template>
      <template #footer>
        <div class="d-flex justify-content-between w-100">
          <st-button
            variant="link"
            class="font-size-h6 px-5 py-3 my-3 mr-3"
            :callback="hide"
          >
            {{ $t("GENERAL.BUTTON.CANCEL") }}
          </st-button>
          <st-button
            v-if="hasPermissionToCreatePredefinedReports"
            variant="primary"
            class="font-size-h6 px-5 py-3 my-3 mr-3"
            :callback="doGenerate"
          >
            <span>{{ $t("APPLICATION.GENERATE_REPORT_MODAL.GENERATE") }}</span>
          </st-button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GenerateApplicationsReportForm from "./GenerateApplicationsReportForm.vue";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";

export default {
  name: "GenerateApplicationsReportModal",
  components: {
    GenerateApplicationsReportForm,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreatePredefinedReports() {
      return new ApplicationsPermissions(this.currentUser).createPredefinedReports;
    }
  },
  methods: {
    show() {
      this.$refs["st-generate-applications-report-modal"].show();
    },
    hide() {
      this.isOrgSelected = false;
      this.$refs["st-generate-applications-report-modal"].hide();
    },
    async doGenerate() {
      const valid = await this.$refs["st-generate-applications-report-form"].doGenerate();
      if (valid) {
        this.hide();
      }  
    }
  },
};
</script>
