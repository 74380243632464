<template>
    <st-page :title="titleComputed" :backLink="backLink" layout="tab" :subtitle="applicationDate"
             pageCustomClass="application-view"
    >
        <b-card class="st-section" id="application-details-target">
            <div class="st-section-body">
                <form class="form"
                      novalidate="novalidate"
                      id="st_internal_application_form"
                >
                    <div>
                        <div class="d-flex">
                            <div class="icon-buttons-container">
                                <i v-if="beneficiaryFound" class="fa fa-check personal-code-validation-icon green"></i>
                                <i v-if="!beneficiaryFound && searchedForSavedBeneficiary"
                                   class="fa fa-times personal-code-validation-icon red personal-code-not-found">
                                </i>
                                <i v-if="searchedForSavedBeneficiary && !beneficiaryFound"
                                   @click="openAddBeneficiaryModal"
                                   class="fa fa-user-plus action-icon personal-code-action-icon">
                                </i>
                            </div>
                            <beneficiary-modal
                                ref="addBeneficiaryModal"
                                @processBeneficiary="processBeneficiary"
                                :items="items"
                                :addMode="true"
                                @cancel="cancel"
                            ></beneficiary-modal>
                            <div class="col p-0 form-group">
                                <label>
                                    {{ fields.personal_numeric_code_or_cif.label }}*
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.personal_numeric_code_or_cif.name"
                                        :name="fields.personal_numeric_code_or_cif.name"
                                        v-model="model[fields.personal_numeric_code_or_cif.name]"
                                        :max-length="13"
                                        @input="checkForSavedBeneficiary($event)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-5">
                            <div class="col-6 pl-0 d-flex">
                                <div class="icon-buttons-container">
<!--                                    <i class="fa fa-check"></i>-->
                                </div>
                                <div class="col p-0 form-group">
                                    <label>
                                        {{ $t("ENTRY_DOCUMENTS.EMAIL") }} *
                                    </label>
                                    <st-input-text
                                        v-model="model.email"
                                        ref="email"
                                        name="email"
                                        :disabled="!beneficiaryFound"
                                    />
                                </div>
                            </div>
                            <div class="col pr-0">
                                <div class="col p-0 form-group">
                                    <label>
                                        {{ $t("ENTRY_DOCUMENTS.PHONE") }} *
                                    </label>
                                    <st-input-text
                                        v-model="model.phone_number"
                                        ref="phone_number"
                                        name="phone_number"
                                        :disabled="!beneficiaryFound"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-for="(doc, key) in model.external_documents" v-bind:key="key">
                            <form class="form d-flex mt-5"
                                  novalidate="novalidate"
                                  :id="`st_internal_application_external_documents_form_${key}`"
                                  v-bind:key="key"
                            >
                                <template v-if="model.external_documents[key] && documentsFieldsList[key]">
                                <div class="col-6 pl-0 d-flex">
                                    <div class="icon-buttons-container new-row-action-icon">
                                        <i class="fa fa-plus-circle action-icon"
                                           @click="addNewExternalDocumentRow"
                                           v-if="model.external_documents.length - 1 === key"></i>
                                        <i class="fa fa-trash action-icon"
                                           @click="removeExternalDocumentRow(key)"
                                           v-if="model.external_documents.length - 1 !== key"></i>
                                    </div>
                                    <div class="col p-0 form-group">
                                        <label>
                                            {{ $t("ENTRY_DOCUMENTS.EXTERNAL_DOCUMENT_NUMBER") }} *
                                        </label>
                                        <st-input-text
                                            v-bind:key="key"
                                            v-model="model.external_documents[key].external_document_number"
                                            :ref="documentsFieldsList[key].external_document_number.name"
                                            :name="documentsFieldsList[key].external_document_number.name"
                                            :disabled="!beneficiaryFound"
                                        />
                                    </div>
                                </div>
                                <div class="col pr-0">
                                    <div class="col p-0 form-group">
                                        <label>
                                            {{ $t("ENTRY_DOCUMENTS.EXTERNAL_DOCUMENT_DATE") }} *
                                        </label>
                                        <st-datepicker
                                            v-model="model.external_documents[key].external_document_date"
                                            :ref="documentsFieldsList[key].external_document_date.name"
                                            :name="documentsFieldsList[key].external_document_date.name"
                                            :disabled="!beneficiaryFound"
                                            @change="validateField(documentsFieldsList[key].external_document_date.name, key)"
                                        />
                                    </div>
                                </div>
                                </template>
                            </form>
                        </div>
                        <div class="d-flex  mt-5">
                            <div class="icon-buttons-container">
                            </div>
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t("ENTRY_DOCUMENTS.RELATED_DOCUMENTS") }} *
                                </label>
                                <StDualListBox
                                    :source="source"
                                    :destination="destination"
                                    label="allocated_series"
                                    @onChangeList="onChangeList"
                                    :disabled="!beneficiaryFound"
                                />
                            </div>
                        </div>
                        <div class="d-flex  mt-5">
                            <div class="icon-buttons-container">
                            </div>
                            <div class="col p-0 form-group">
                                <label>
                                    {{ fields.application_type_id.label }} *
                                </label>
                                <st-select
                                    v-model="model[fields.application_type_id.name]"
                                    :ref="fields.application_type_id.name"
                                    :field="fields.application_type_id"
                                    :formValidation="fv"
                                    searchable
                                    :disabled="!beneficiaryFound"
                                />
                            </div>
                        </div>
                        <div class="d-flex  mt-5">
                            <div class="icon-buttons-container">
                            </div>
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t("ENTRY_DOCUMENTS.DETAILS") }}
                                </label>
                                <st-input-text
                                    v-model="model.details"
                                    ref="details"
                                    name="details"
                                    :disabled="!beneficiaryFound"
                                />
                            </div>
                        </div>
                        <div class="d-flex  mt-5">
                            <div class="icon-buttons-container">
                            </div>
                            <div class="col p-0 form-group">
                                <label>
                                    {{ $t("ENTRY_DOCUMENTS.DOCUMENTS") }}
                                </label>
                                <div>
                                    <div :class="['drop-zone', dragging ? 'drop-zone-over' : '']"
                                         @dragenter="dragging=true" @dragleave="dragging=false">
                                        <div class="drop-zone-info" @drag="onChange">
                                            <div class="drop-zone-info-texts">
                                                <span class="drop-zone-title">
                                                    {{ $t("ENTRY_DOCUMENTS.FILE_UPLOAD_INFO_MESSAGE") }}
                                                </span>
                                                <div class="drop-zone-upload-limit-info">
                                                    <div>{{ $t("ENTRY_DOCUMENTS.MAX_FILE_SIZE") }}</div>
                                                </div>
                                                <div v-if="fileUploadError" class="red">
                                                    {{ $t("ENTRY_DOCUMENTS.FILE_UPLOAD_ERROR") }}
                                                </div>
                                            </div>
                                        </div>
                                        <input type="file" @change="onChange" multiple>
                                    </div>
                                </div>
                                <div v-if="files.length" class="file-names">
                                    <div class="text-center" v-for="(file, fileKey) in files" v-bind:key="fileKey">
                                        {{file.name}}
                                        <i class="fa fa-trash remove-file-icon font-size-sm ml-2"
                                           @click="removeFile(fileKey)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <st-button type="button" class="btn btn-primary d-block center-item-margin" :callback="createDocument">
                        {{ $t("ENTRY_DOCUMENTS.REGISTER_DOCUMENT") }}
                    </st-button>
                </form>
            </div>
        </b-card>
    </st-page>

</template>

<script>
import {ApplicationsPermissions} from "@/modules/applications/applications-permissions";
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import BeneficiaryModal from "@/modules/applications/components/beneficiary/BeneficiaryModal";
import {EntryApplicationModel} from "@/modules/applications/models/entry-application-model";
import {FormSchema} from "@/shared/form/form-schema";
import {createFormValidation} from "@/shared/utils/create-form-validation";
import StringField from "@/shared/fields/string-field";
import StButton from "@/shared/components/StButton.vue";
import i18n from "@/shared/plugins/vue-i18n";
import StDualListBox from "@/shared/components/dual-listbox/StDualListBox.vue";
const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const { fields } = EntryApplicationModel;
const formSchema = new FormSchema([
    fields.id,
    fields.personal_numeric_code_or_cif,
    fields.email,
    fields.phone_number,
    fields.application_type_id,
    fields.details
])

let formSchemaExternalDocuments = [];

export default {
    name: "EntryDocumentsForm",
    components: {StDualListBox, StButton, BeneficiaryModal},
    props: {
    },
    data() {
        return {
            fields,
            documentsFieldsList: [
                {
                    external_document_number: new  StringField('external_document_number', label('external_document_number'), { required: true }),
                    external_document_date: new  StringField('external_document_date', label('external_document_date'), { required: true }),
                }
            ],
            model: {
                personal_numeric_code_or_cif: null,
                email: null,
                phone_number: null,
                external_documents: [
                    {
                        external_document_number: null,
                        external_document_date: null,
                    }
                ],
                related_number: null,
                application_type_id: null,
                details: null
            },
            beneficiaryFound: Boolean,
            searchedForSavedBeneficiary: false,
            rules: formSchema.rules(),
            externalDocumentsRules: [],
            fv: null,
            documentsFv: [],
            validPersonalNumericCodeOrCif: false,
            items: [],
            existingBeneficiary: null,
            source: [],
            destination: [],
            files: [],
            dragging: false,
            fileUploadError: false,
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
        }),
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
        },
        titleComputed() {
            return this.$t("ENTRY_DOCUMENTS.TITLE");
        },
        backLink() {
            return  { name: "dashboard" };
        },
        applicationDate() {
            if (!this.record?.application_date) return '';
            return moment(new Date(this.record.application_date)).format(this.appConfig.DATE_FORMAT);
        },
    },
    methods: {
        ...mapActions({
            getExistingBeneficiary: 'applications/form/doFindBeneficiaryByPncOrUrc',
            doAddBeneficiary: "applications/form/doAddBeneficiary",
            doCreate: "applications/form/createEntryDocument",
            getRelatedDocuments: "applications/form/getRelatedDocuments",
        }),
        async checkForSavedBeneficiary(inputValue) {
            if (this.validPersonalNumericCodeOrCif) {
                const params = {
                    code: inputValue
                }
                this.searchedForSavedBeneficiary = true;
                const beneficiary = await this.searchForBeneficiary({params});
                if (!beneficiary) {
                    return;
                }
                this.existingBeneficiary = beneficiary;
                this.model.email = this.existingBeneficiary.email;
                this.model.phone_number = this.existingBeneficiary.phone_number;
                this.beneficiaryFound = true;
                const documents = await this.getRelatedDocuments(inputValue)
                this.source = documents.data.organisations;
                this.destination = documents.data.users;
            }
        },
        async searchForBeneficiary(params) {
           return await this.getExistingBeneficiary(params);
        },
        openAddBeneficiaryModal() {
            this.$refs.addBeneficiaryModal.show();
        },
        async processBeneficiary(data) {
            this.items.push({
                ...data.beneficiaryDetails,
                index: this.items.length
            });
            this.existingBeneficiary = await this.doAddBeneficiary(data.beneficiaryDetails);
            this.model.email = this.existingBeneficiary.email;
            this.model.phone_number = this.existingBeneficiary.phone_number;
            this.beneficiaryFound = true;
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        addNewExternalDocumentRow() {
            if (!this.beneficiaryFound) {
                return;
            }
            this.model.external_documents.push(
                {
                    external_document_number: null,
                    external_document_date: null,
                }
            );
            const documentsFields = {
                external_document_number: new  StringField('external_document_number', label('external_document_number'), { required: true }),
                external_document_date: new  StringField('external_document_date', label('external_document_date'), { required: true }),
            }
            this.documentsFieldsList.push(documentsFields);
            const key = (this.documentsFieldsList.length - 1);
            formSchemaExternalDocuments.push(
                new FormSchema([
                    this.documentsFieldsList[key].external_document_number,
                    this.documentsFieldsList[key].external_document_date,
                ])
            );
            this.externalDocumentsRules.push(formSchemaExternalDocuments[key].rules());
            this.$nextTick(()=>{
                this.documentsFv.push(
                    createFormValidation(`st_internal_application_external_documents_form_${key}`, this.externalDocumentsRules[key])
                );
            })
        },
        removeExternalDocumentRow(index) {
            this.model.external_documents.splice(index, 1);
            this.documentsFieldsList.splice(index, 1);
            formSchemaExternalDocuments.splice(index, 1);
            this.externalDocumentsRules.splice(index, 1);
            this.documentsFv = [];
            this.$nextTick(()=>{
                let i = 0;
                for (const externalDocumentsRule of this.externalDocumentsRules) {

                this.documentsFv.push(
                    createFormValidation(`st_internal_application_external_documents_form_${i}`, this.externalDocumentsRules[i])
                );
                i++;
                }
            })
        },
        async createDocument() {
            const isValid = await this.fv.validate();
            const allDocumentsValidity = [];
            for (const documentsFvElement of this.documentsFv) {
                const isValidDocument = await documentsFvElement.validate();
                allDocumentsValidity.push(isValidDocument);
            }
            const documentsValid = allDocumentsValidity.some((documentValid) => {
                return documentValid === 'Invalid'
            })
            if (isValid === 'Valid' && !documentsValid) {
                const cast = formSchema.cast(this.model);
                cast.beneficiary_users = [this.existingBeneficiary];
                cast.related_numbers = this.destination.map(doc => doc.id);
                //     TODO send the uploaded files from files variable to the backend and handle them and also a redirect on success to finalization page
                const result = await this.doCreate(cast)
            }
        },
        async validateField(field, key) {
            if (this.model.external_documents[key][field]) {
                await this.documentsFv[key].updateFieldStatus(field, 'Valid');
            } else {
                await this.documentsFv[key].updateFieldStatus(field, 'Invalid');
            }
        },
        onChangeList: function ({ source, destination }) {
            this.source = source;
            this.destination = destination;
        },
        onChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.fileUploadError = false;
            for (const file of files) {
                this.createFile(file);
            }
        },
        createFile(file) {
            if (file.size > 52428800) {
                this.fileUploadError = true;
                this.dragging = false;
                return;
            }

            this.files.push(file);
        },
        removeFile(index) {
            this.files.splice(index, 1);
        }
    },
    mounted() {
        this.beneficiaryFound = false;
        this.fv = createFormValidation('st_internal_application_form', this.rules);
        this.fv.on('core.field.valid', (field) => {
            if (field === 'personal_numeric_code_or_cif') {
                this.validPersonalNumericCodeOrCif = true;
            }
        })
        this.fv.on('core.field.invalid', (field) => {
            if (field === 'personal_numeric_code_or_cif') {
                this.validPersonalNumericCodeOrCif = false;
            }
        })
        formSchemaExternalDocuments = [
            new FormSchema([
                this.documentsFieldsList[0].external_document_number,
                this.documentsFieldsList[0].external_document_date,
            ])
        ];``
        this.externalDocumentsRules = [
            formSchemaExternalDocuments[0].rules()
        ];
        this.documentsFv =  [
            createFormValidation('st_internal_application_external_documents_form_0', this.externalDocumentsRules[0])
        ];
    },
};
</script>
