import * as yup from "yup";
import GenericField from "@/shared/fields/generic-field";
import { validators } from "@/shared/form/validators";

export default class StringField extends GenericField {
    constructor(
        name,
        label,
        {
            required = false,
            min = undefined,
            max = undefined,
            personalNumericCode = false,
            iban = false,
            uniqueRegistrationCode = false,
            phoneNumber = false,
            regexp = undefined,
            minDate = undefined,
            alphabetical = false,
            alphabeticalSpecialChars = false,
            noSpace = false,
            stringLength = undefined,
            maxDate = undefined,
            url = false,
            full_url = false,
            arcgisFeatureServiceUrl = false,
            type = "search",
            placeholder = undefined,
            atLeastOneColumn = false,
            personalNumericCodeOrUniqueRegistrationCode = false,
        } = {},
        filterParams = {}
    ) {
        super(name, label, filterParams);

        this.type = type;
        this.required = required;
        this.min = min;
        this.max = max;
        this.personalNumericCode = personalNumericCode;
        this.iban = iban;
        this.uniqueRegistrationCode = uniqueRegistrationCode;
        this.phoneNumber = phoneNumber;
        this.regexp = regexp;
        this.minDate = minDate;
        this.alphabetical = alphabetical;
        this.alphabeticalSpecialChars = alphabeticalSpecialChars;
        this.noSpace = noSpace;
        this.stringLength = stringLength;
        this.maxDate = maxDate;
        this.url = url;
        this.full_url = full_url;
        this.arcgisFeatureServiceUrl = arcgisFeatureServiceUrl;
        this.placeholder = filterParams?.placeholder ?? placeholder;
        this.atLeastOneColumn = atLeastOneColumn;
        this.personalNumericCodeOrUniqueRegistrationCode = personalNumericCodeOrUniqueRegistrationCode;
    }

    forPresenter(value) {
        return value;
    }

    forFormInitialValue(value) {
        return value;
    }

    forFilterInitialValue(value) {
        return value;
    }

    forFilterRules() {
        return undefined;
    }

    forFormRules() {
        let output = {};

        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }

        if (this.min || this.min === 0) {
            output = {
                ...output,
                ...validators.minLength(this.min),
            };
        }

        if (this.max || this.max === 0) {
            output = {
                ...output,
                ...validators.maxLength(this.max),
            };
        }

        if (this.personalNumericCode) {
            output = {
                ...output,
                ...validators.personalCode(),
            };
        }


        if (this.personalNumericCodeOrUniqueRegistrationCode) {
            output = {
                ...output,
                ...validators.personalNumericCodeOrUniqueRegistrationCode(),
            };
        }

        if (this.iban) {
            output = {
                ...output,
                ...validators.iban(),
            };
        }

        if (this.uniqueRegistrationCode) {
            output = {
                ...output,
                ...validators.uniqueCode(),
            };
        }

        if (this.phoneNumber) {
            output = {
                ...output,
                ...validators.phoneNumber(),
            };
        }

        if (this.regexp) {
            output = {
                ...output,
                ...validators.regexp(this.regexp),
            };
        }

        if (this.minDate) {
            output = {
                ...output,
                ...validators.minDate(this.minDate),
            };
        }

        if (this.maxDate) {
            output = {
                ...output,
                ...validators.maxDate(this.maxDate),
            };
        }

        if (this.alphabetical) {
            output = {
                ...output,
                ...validators.alphabetical(),
            };
        }

        if (this.alphabeticalSpecialChars) {
            output = {
                ...output,
                ...validators.alphabeticalSpecialChars(),
            };
        }

        if (this.noSpace) {
            output = {
                ...output,
                ...validators.noSpace(),
            };
        }

        if (this.stringLength) {
            output = {
                ...output,
                ...validators.minMax(this.stringLength),
            };
        }

        if (this.url) {
            output = {
                ...output,
                ...validators.url(),
            };
        }

        if (this.full_url) {
            output = {
                ...output,
                ...validators.full_url(),
            };
        }

        if (this.arcgisFeatureServiceUrl) {
            output = {
                ...output,
                ...validators.arcgisFeatureServiceUrl(),
            };
        }

        if (this.atLeastOneColumn) {
            output = {
                ...output,
                ...validators.atLeastOneColumn(),
            };
        }

        return output;
    }

    forFormCast() {
        let yupChain = yup.string().nullable(true).trim().label(this.label);

        return yupChain;
    }

    forFilterCast() {
        return yup.string().nullable(true).trim().label(this.label);
    }
}
