import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EmailField from '@/shared/fields/email-field';
import { LocalityField } from '@/shared/common-fields/locality-field';
import { CountyField } from '@/shared/common-fields/county-field';
import { VillageField } from '@/shared/common-fields/village-field';
import { CountryField } from '@/shared/common-fields/country-field';
import BooleanField from '@/shared/fields/boolean-field';
import ComputedField from '@/shared/fields/computed-field';
import { BeneficiaryField } from '@/modules/applications/fields/beneficiary-field';
import IntegerField from "@/shared/fields/integer-field";
import {ApplicationTypeField} from "@/modules/applications/fields/application-type-field";

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    personal_numeric_code_or_cif: new StringField('personal_numeric_code_or_cif', label('personal_numeric_code_or_cif'), { personalNumericCodeOrUniqueRegistrationCode: true }),
    email: new EmailField('email', label('email'), { required: true }),
    phone_number: new StringField('phone_number', label('phone_number'), { required: true, phoneNumber: true }),
    application_type_id: ApplicationTypeField.relationToOne(
        'application_type_id',
        label('application_type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('application_type_name')},
        [],
        { asyncData: true, hasPagination: true }
    ),
    details: new StringField('details', label('details'), {}),
};

const documentsFields = {
    external_document_number: new  StringField('external_document_number', label('external_document_number'), { required: true }),
    external_document_date: new  StringField('external_document_date', label('external_document_date'), { required: true }),
}

const documentsFieldsList = [documentsFields];

export class EntryApplicationModel extends GenericModel {
    static get fields() {
        return fields;
    }

    static get documentsFields() {
        return documentsFields;
    }

    static get documentsFieldsList() {
        return documentsFieldsList;
    }
}
