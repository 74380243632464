import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import { ApplicationTypeField } from '@/modules/applications/fields/application-type-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`APPLICATION.GENERATE_REPORT_MODAL.${name.toUpperCase()}`);

const fields = {
    name: new StringField('name',
        label('name'),
        {
            required: true
        }
    ),
    start_date: new DateField(
        'start_date',
        label('start_date'),
        {
            required: true,
        },
    ),
    end_date: new DateField(
        'end_date',
        label('end_date'),
        {
            required: true,
        },
    ),
    application_type_id: ApplicationTypeField.relationToOne(
        'application_type_id',
        label('application_type_name'),
        { autocompleteRequired: true },
        { placeholder: label('application_type_name_placeholder') },
        [],
        { asyncData: true, hasPagination: true }
    ),
    destinations: new StringField('destinations',
        label('columns'),
        {
            atLeastOneColumn: true,
        }
    ) 
};

export class ApplicationReportModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
