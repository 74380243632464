import i18n from "../plugins/vue-i18n";
import { storeAsync } from '@/app-module';

export const validators = {
    required: () => {
        return {
            notEmpty: {
                message: i18n.t("AUTH.VALIDATION.REQUIRED_FIELD"),
            },
        };
    },

    numeric: () => {
        return {
            numeric: {
                message: i18n.t("AUTH.VALIDATION.NUMERIC_FIELD"),
            },
        };
    },
    decimal: () => {
        return {
            numeric: {
                message: i18n.t('AUTH.VALIDATION.NUMERIC_FIELD'),
                thousandsSeparator: '',
                decimalSeparator: storeAsync().getters['shared/appConfig'].DECIMAL_SEPARATOR,
            }
        }
    },
    integer: () => {
        return {
            integer: {
                message: i18n.t('AUTH.VALIDATION.INTEGER'),
            }
        }
    },
    min: (value) => {
        return {
            greaterThan: {
                min: value,
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },
    max: (value) => {
        return {
            lessThan: {
                max: value,
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },
    minLength: (value) => {
        return {
            stringLength: {
                min: value,
                message: i18n.t('AUTH.VALIDATION.MIN_LENGTH_FIELD', { min: value }),
            },
        };
    },

    maxLength: (value) => {
        return {
            stringLength: {
                max: value,
                message: i18n.t('AUTH.VALIDATION.MAX_LENGTH_FIELD', { max: value }),
            },
        };
    },

    phoneNumber: () => {
        return {
            phoneNumber: {
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },

    regexp: (regexpValue) => {
        return {
            regexp: {
                regexp: regexpValue,
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },

    personalCode: () => {
        return {
            pnc: {
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },

    uniqueCode: () => {
        return {
            urc: {
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },

    personalNumericCodeOrUniqueRegistrationCode: () => {
        return {
            pncOrUrc: {
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },

    emailAddress: () => {
        return {
            emailAddress: {
                message: i18n.t("AUTH.VALIDATION.INVALID_EMAIL"),
            },
        };
    },

    callback: (value) => {
        return {
            callback: {
                message: value.message,
                callback: value.fnc,
            },
        };
    },

    minMax: (value) => {
        return {
            stringLength: {
                max: value.max,
                min: value.min,
                message: i18n.t("AUTH.VALIDATION.INVALID_FIELD"),
            },
        };
    },

    autocompleteRequired: (enabled) => {
        return {
            callback: {
                enabled,
                message: i18n.t("AUTH.VALIDATION.REQUIRED_FIELD"),
                callback: (input) => {
                    // get value from vue component attached to html element
                    const value = input.element.__vue__.value;
                    return !!value;
                },
            },
        };
    },

    iban: () => {
        return {
            iban: {
                country: 'RO',
                message: i18n.t('AUTH.VALIDATION.IBAN')
            }
        };
    },

    minDate: (min) => {
        return {
            date: {
                format: 'YYYY-MM-DD',
                message: i18n.t("AUTH.VALIDATION.INVALID_DATE"),
                min,
            }
        }
    },

    maxDate: (max) => {
        return {
            date: {
                format: 'YYYY-MM-DD',
                message: i18n.t("AUTH.VALIDATION.INVALID_DATE"),
                max,
            }
        }
    },

    alphabetical: () => {
        return {
            regexp: {
                regexp: /^[a-zA-Z\s]+$/,
                message: i18n.t("AUTH.VALIDATION.ALPHABETICAL")
            }
        }
    },

    noSpace: () => {
        return {
            regexp: {
                regexp: /^\S*$/,
                message: i18n.t("AUTH.VALIDATION.NO_SPACE")
            }
        }
    },

    alphabeticalSpecialChars: () => {
        return {
            regexp: {
                regexp: /^([a-zA-Z]+)(([-'\s]{1})?[a-zA-Z]+)+?$/,
                message: i18n.t("AUTH.VALIDATION.ALPHABETICAL_SPECIAL_CHARS")
            }
        }
    },

    date: (format) => {
        return {
            date: {
                format,
                message: i18n.t("AUTH.VALIDATION.INVALID_DATE"),
            },
        }
    },

    url: () => {
        return {
            regexp: {
                regexp: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                message: i18n.t("AUTH.VALIDATION.URL")
            }
        }
    },

    full_url: () => {
        return {
            regexp: {
                regexp: /^(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                message: i18n.t("AUTH.VALIDATION.FULL_URL")
            }
        }
    },

    arcgisFeatureServiceUrl: () => {
        return {
            regexp: {
                // regexp: new RegExp(`^${window.VUE_APP_ARCGIS_SERVICES_URL.replace(/(?=\W)/g, '\\')}\\/([a-zA-Z0-9\_]{1,360})\\/FeatureServer$`),
                regexp: new RegExp(`${window.VUE_APP_ARCGIS_SERVICES_URL.replace(/(?=\W)/g, '\\')}\\/(.*)\\/(.*)\\/FeatureServer`),
                message: i18n.t("AUTH.VALIDATION.ARCGIS_FEATURE_SERVICE_URL")
            }
        }
    },

    atLeastOneColumn: () => {
        return {
            notEmpty: {
                message: i18n.t("AUTH.VALIDATION.АT_LEAST_ONE_COLUMN"),
            },
        };
    },
};
