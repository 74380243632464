<template>
  <v-select
    v-model="textInput"
    :name="name"
    ref="inputSelectRef"
    class="st-search form-control"
    :options="options"
    :clearable="true"
    :searchable="true"
    :placeholder="placeholder"
    :taggable="true"
    :push-tags="true"
    @input="onInput"
    @option-created="onOptionCreated"
  >
    <template #option="option">
      <span>{{ option.label }}</span>
    </template>
    <template #list-header>
      <li class="text-center p-4">
        {{ $t("APPLICATION.GENERATE_REPORT_MODAL.NAME_DESCRIPTION") }}
      </li>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "TextDropdownInput",
  components: { vSelect },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true
    },
    formValidation: {
      type: Object
    }
  },
  data() {
    return {
      textInput: "",
    };
  },
  methods: {
    onInput(value) {
      if (value) {
        this.formValidation.disableValidator(this.name);
      } else {
        this.formValidation.enableValidator(this.name);
      }
      this.textInput = value;
      this.$emit("update", value);
    },
    onOptionCreated(pushTagFunction) {
      const select = this.$refs.inputSelectRef;
      pushTagFunction(select.search);
    },
  },
};
</script>

