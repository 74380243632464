<template>
    <div>
        <st-modal
            id="st-permissions-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="xl"
            hideable
            ref="st-permissions-modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-4 pb-0">{{ $t('PERMISSIONS.FORM.TITLE') }} asd</p>
            </template>
            <template #body>
                <permissions-form
                    ref="permissions-form"
                    @cancelForm="hide"
                    @organisationSelected="onOrganisationSelected"
                >
                </permissions-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="doSave"
                        :disabled="!isOrgSelected"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import PermissionsForm from './PermissionsForm.vue';

export default {
    name: 'PermissionsModal',
    components: { PermissionsForm },
    data() {
        return {
           isOrgSelected: false
        }
    },
    methods: {
        show() {
            this.$refs['st-permissions-modal'].show();
        },
        hide() {
            this.isOrgSelected = false;
            this.$refs['st-permissions-modal'].hide();
        },
        doSave() {
            this.$refs['permissions-form'].doSave();
        },
        onOrganisationSelected() {
            this.isOrgSelected = true;
        }
    },
}
</script>
