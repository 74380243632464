<template>
    <div>
        <Loader v-if="isLoading"/>
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="font-size-h6">
                        {{fields.organisations.label}}
                    </label>
                    <b-form-select
                        :name="fields.organisations.name"
                        v-model="model[fields.organisations.name]"
                        :options="fields.organisations.options"
                    >
                    </b-form-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="form-group">
                    <label class="font-size-h6">
                        {{ $t('PERMISSIONS.FORM.PERMISSIONS')}}
                    </label>
                        <StDualListBox
                        :source="source"
                        :destination="destination"
                        label="permission_name"
                        @onChangeList="onChangeList"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { PermissionsModel } from '@/modules/permissions/permissions-model';
import StDualListBox from '@/shared/components/dual-listbox/StDualListBox';

const { fields } = PermissionsModel;
const formSchema = new FormSchema([
    fields.organisations,
])
export default {
    name: 'PermissionsModal',
    components: {
        StDualListBox
    },
    data() {
        return {
            model: formSchema.initialValues({}),
            fields,
            source: [],
            destination: [],
        }
    },
    computed: {
        ...mapGetters({
            organisation: 'permissions/form/organisation',
            permissions: 'permissions/form/permissionsByOrgScope',
            loading: 'shared/loading',
        }),
        isLoading () {
            return this.loading['permissions/getPermissionsByOrgScope'] ||
                   this.loading['permissions/getOrganisation'];
        },
    },
    watch: {
        'model.organisations': {
            deep: true,
            immediate: true,
            handler(id) {
                if (id) {
                    this.$emit('organisationSelected');
                    this.doFetchOrganisation(id)
                    .then(() => {
                        this.source = this.permissions;
                        this.destination = this.source.filter(el=> this.organisation.permissions.includes(el.name));
                        this.source = this.source.filter(el=> !this.organisation.permissions.includes(el.name));
                    })
                }
            },
        },
    },
    created() {
        this.doFetchPermissionsByOrgScope()
        .then(() => {
            this.orgPermissions = this.permissions;
        })
    },
    methods: {
        ...mapActions({
            doUpdatePermissions: 'permissions/form/addPermissionsToOrganisation',
            doFetchOrganisation: 'permissions/form/fetchOrganisation',
            doFetchPermissionsByOrgScope: 'permissions/form/fetchPermissionsByOrgScope',
        }),
        show() {
            this.$refs['st-permissions-modal'].show();
        },
        hide() {
            this.doClear();
            this.$emit('cancelForm');
        },
        doClear() {
             this.source.forEach(element => {
                element.selected = false;
            });
            this.destination = [];
            this.source = this.permissions;
            this.model.organisations = '';
        },
        doSave() {
            const selectedOrgId = this.model.organisations;
            let selectedPermissions = this.destination.map(el => el.name);

            this.doUpdatePermissions({id: selectedOrgId, permissions: selectedPermissions})
            .then(() => {
                this.hide();
            })
        },
        onChangeList: function ({ source, destination }) {
            this.source = source;
            this.destination = destination;
        }
    },
}
</script>
