import { IsPersonalNumericCodeConstraint } from './pnc'
import urc from './urc'

export default function pncOrUrc() {
    return {
        validate(input) {
            const value = input.value;
            const validAsPersonalNumericCode = new IsPersonalNumericCodeConstraint().validate(value);
            const urcValidatorInstance = new urc();
            const validAsUniqueRegistrationCode = urcValidatorInstance.validate(input);
            if (validAsPersonalNumericCode === true || validAsUniqueRegistrationCode.valid === true) {
                return { valid: true };
            }
            return { valid: false };
        }
    }
}
